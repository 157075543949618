import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { getAllAvailableProducts, getAllAvailableProductsGroupRack } from "../../../services/Warehouse";
import { alertOptions, removeAccents } from "../../../utils/Utilities";
import TableProducts from "./TableProducts";

const FormProducts = ({ idWarehouse, nameWarehouse, productSelected, productsCurrent, onProduct, onLoader, onClose, onCompatible }) => {
  const [products, setProducts] = useState([]);
  const [productsUniv, setProductsUniv] = useState([]);
  const [product, setProduct] = useState({ _id: "", _idRack: "", name: "", quantity: "" });

  const handleFilters = (wordFilter) => {
    if (wordFilter === "") {
      setProducts(productsUniv);
    } else {
      let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
      const productsFound = productsUniv.filter(element => (
        palabra.test(`${removeAccents(element?.productInventory?.product?.nags)}`) ||
        palabra.test(`${removeAccents(element?.productInventory?.product?.name)}`) ||
        palabra.test(`${removeAccents(element?.productInventory?.product?.year)}`) ||
        palabra.test(`${removeAccents(element?.productInventory?.product?.model)}`) ||
        palabra.test(`${removeAccents(element?.productInventory?.product?.brand)}`)
      ));
      setProducts(productsFound);
    }
  };

  const handleSelect = (productElement) => {
    const newProducts = productsUniv.map(elem => {
      if (productElement?.productInventory?._id === elem?.productInventory?._id) return {...elem, isSelected: true};
      return {...elem, isSelected: false};
    });
    setProductsUniv(newProducts);
    setProducts(newProducts);
    setProduct({...product,
      _id: productElement?.productInventory?._id,
      _idRack: "",
      productInventory: productElement?.productInventory,
      name: productElement?.productInventory?.product?.name,
      quantity: productElement.quantity <= 0 ? 1 : productElement.quantity
    });
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "quantity" && !(/^[0-9]*$/).test(evt.currentTarget.value)) {
      return;
    }
    if (evt.currentTarget.name === "quantity") {
      setProduct({...product, quantity: Number(evt.currentTarget.value)});
    } else {
      setProduct({...product, [evt.currentTarget.name]: evt.currentTarget.value});
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (product._id !== "" && product.name !== "" && product.quantity !== "") {
      if (product.quantity > 0) {
        onProduct(product, productSelected?.createdAt ? true : false);

        //const maxQuantity = products.find(element => element?.productInventory?._id === product._id);
        // if (product.quantity <= maxQuantity.quantity) {
        //   onProduct(product, productSelected?.createdAt ? true : false);
        // } else {
        //   toast.warning(`The quantity cannot be greater than ${maxQuantity.quantity}`, alertOptions);
        // }
      } else {
        toast.warning("You cannot add a product with 0 quantity", alertOptions);
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  useEffect(() => {
    if (productSelected !== null) {
      setProduct({...product,
        _id: productSelected?.productInventory?._id,
        _idRack: productSelected?._id,
        name: productSelected?.productInventory?.product?.name,
        productInventory: productSelected?.productInventory,
        quantity: productSelected?.quantity
      });
    }
  }, [productSelected]);

  useEffect(() => {
    onLoader(true);
    getAllAvailableProducts(idWarehouse).then(res => {
      getAllAvailableProductsGroupRack(idWarehouse).then(resAP => {
        if (res.status === 200 && res.data.allAvailableProducts.length > 0) {
          const productsWithOutRack = resAP.data.allAvailableProducts.filter(elem => elem?.racks.length === 0).map(elem => ({
            productInventory: elem?.productInventory,
            quantity: 0,
            rack: {},
            _id_rackAndProductRelation: ""
          }));

          // Se calcula el valor restante del producto
          const arrAvailableProducts = res.data.allAvailableProducts.concat(productsWithOutRack);
          const arrayUniqueByProduct = [...new Map(arrAvailableProducts.map(item =>[item.productInventory._id, item])).values()];

          const newArrAvailableProducts = arrayUniqueByProduct.map(prodElement => {
            let sumQuantity = 0;
            for (let i = 0; i < arrAvailableProducts.length; i++) {
              if (arrAvailableProducts[i].productInventory._id === prodElement.productInventory._id) {
                sumQuantity += Number(arrAvailableProducts[i].quantity);
              }
            }

            if (productSelected !== null && prodElement.productInventory._id === productSelected.productInventory._id) {
              if (productSelected?.createdAt) sumQuantity -= productSelected.quantity;
            }

            return {...prodElement, quantity: prodElement.productInventory.maxStock - sumQuantity };
          }).filter(element => {
            const productAdd = productsCurrent.find(elementAux => elementAux.productInventory._id === element.productInventory._id);
            if (!productAdd || productSelected !== null) return element;
          });

          if (productSelected) {
            const arrOnlyProduct = newArrAvailableProducts.filter(ele => ele.productInventory._id === productSelected.productInventory._id);
            setProducts(arrOnlyProduct);
            setProductsUniv(arrOnlyProduct);
          } else {
            setProducts(newArrAvailableProducts);
            setProductsUniv(newArrAvailableProducts);
          }
        }
      });
      onLoader(false);
    });
  }, []);

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[700px] lg:w-[950px]">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl text-ag-secondary font-bold">
                    Products available at location: { nameWarehouse }
                  </span>
                </div>
                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>
              <div className="relative px-2 md:px-5 pb-2 pt-5 flex-auto">
                <form onSubmit={handleSubmit}>
                  <div className="space-y-3">
                    <div className="grid gap-3 md:grid-cols-4">
                      <div className={`w-full flex justify-center items-center ${ productSelected ? "md:col-span-3" : "md:col-span-2" }`}>
                        <span
                          className={`${ productSelected ? "w-[42%] md:w-[30%]" : "w-[42%]" } bg-ag-secondary text-white text-xs lg:text-sm text-center border border-gray-500 px-2 py-3 rounded-l-xl`}
                        >
                          Product Name
                        </span>
                        <input
                          className={`${ productSelected ? "w-[58%] md:w-[70%]" : "w-[58%]" } text-ag-secondary-letter text-xs lg:text-sm border border-gray-500 px-2 py-3 rounded-r-xl disabled:bg-zinc-200`}
                          type="text"
                          name="name"
                          onChange={handleChange}
                          value={product.name}
                          disabled={true}
                        />
                      </div>
                      <div className="w-full flex justify-center items-center space-x-2 border border-gray-500 bg-ag-secondary rounded-xl">
                        <div className="w-[40%] md:w-auto text-center">
                          <span
                            className="text-white text-xs lg:text-sm text-center py-2"
                          >
                            Quantity:
                          </span>
                        </div>
                        <div className="w-[60%] md:w-[65px] h-10 md:h-8 flex items-center">
                          <input
                            className="w-[90%] md:w-full h-8 text-ag-secondary-letter text-center text-xs lg:text-sm border border-gray-500 p-1"
                            type="text"
                            name="quantity"
                            value={product.quantity}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {
                        productSelected ? null :
                          <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                            <input
                              className="w-full text-center text-ag-secondary-letter text-xs lg:text-sm border-none p-2 rounded-xl disabled:bg-zinc-200"
                              type="text"
                              name="search"
                              placeholder="Search product"
                              onChange={(evt) => handleFilters(evt.currentTarget.value)}
                              disabled={productSelected ? true : false}
                            />
                            <div
                              className={`bg-ag-primary-dark text-xs lg:text-sm border-none p-[10px] lg:p-[12px] rounded-xl ${productSelected ? 'opacity-50' : ''}`}
                            >
                              <span className="text-white text-xl"><BiSearch /></span>
                            </div>
                          </div>
                      }
                    </div>
                    <div className="overflow-auto h-[274px]">
                      <TableProducts
                        products={products}
                        available={true}
                        productSelected={productSelected}
                        onSelect={handleSelect}
                        onCompatible={onCompatible}
                      />
                    </div>
                    <div className="flex justify-center items-center space-x-8 w-full md:w-[60%] m-auto">
                      <button
                        className="w-full bg-ag-primary-light text-white text-xs lg:text-sm px-2 py-3 rounded-xl"
                        type="button"
                        onClick={() => onClose(true)}
                      >
                        Cancel
                      </button>
                      <button
                        className="w-full bg-green-600 text-white text-xs lg:text-sm px-2 py-3 rounded-xl"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default FormProducts;