import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { fileUpload } from "../../../../services/File";
import { createAccesory, deleteAccessory, updateAccesory } from "../../../../services/Accessories";
import { Link } from "react-router-dom";
import Select from 'react-select';
import Modal from "../../../../components/modal/Modal";

const INITIAL_STATE = {
  name: "",
  price: "",
  barcode1: "",
  barcode2: "",
  description: "",
  notes: "",
  vendors: [],
  images: [],
  status: true,
  updatedAt: '',
};

function AccessoryModal({ currentAccessory, isOpen, onClose, refreshAccessoryList, vendors }) {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const [imageUpload, setImageUpload] = useState(Array(12).fill({ name: "", file: null, url: null }));
  const fileInputRefs = useRef(Array(12).fill(null));
  const [fieldErrors, setFieldErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [maxCharReached, setMaxCharReached] = useState({
    name: false,
    price: false,
    description: false,
    notes: false,
  });

  const handleClick = (index) => {
    fileInputRefs.current[index].click();
  };

  const deleteArrImage = (index) => {
    const newImageUpload = imageUpload.map((imgEle, i) => {
      if (index === i) {
        return { name: "", file: null, url: null };
      }
      return imgEle;
    });

    setImageUpload(newImageUpload);
  };

  const handleClose = () => {
    setFormData(INITIAL_STATE);
    setImageUpload(Array(12).fill({ name: "", file: null, url: null }));
    setMaxCharReached({
      name: false,
      price: false,
      description: false,
      notes: false,
    });
    setFieldErrors({});
    onClose();
  };

  const handleFileChange = (evt, index) => {
    const file = evt.target.files[0];
    if (!file) return;

    if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      toast.warning("Only png, jpg, or jpeg files are allowed", alertOptions);
    } else {
      const updatedImages = imageUpload.map((img, i) =>
        i === index ? { name: file.name, file, url: null } : img
      );
      setImageUpload(updatedImages);
    }
  };

  const handleInputChange = (e, name) => {
    if (name === "vendors") {
      const selectedVendors = [...e].map(option => option);

      setFormData(prevData => ({
        ...prevData,
        [name]: selectedVendors
      }));

      if (selectedVendors.length > 0) {
        setFieldErrors(prevErrors => ({ ...prevErrors, vendors: false }));
      }
    } else {
      const { name, value, maxLength } = e.target;

      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));

      if (value.trim() !== "") {
        setFieldErrors(prevErrors => ({ ...prevErrors, [name]: false }));
      }

      setMaxCharReached((prevMax) => ({
        ...prevMax,
        [name]: value.length >= maxLength,
      }));
    }
  };

  const validateAndUploadImages = async () => {
    const uploadedImages = [];

    for (let i = 0; i < imageUpload.length; i++) {
      const { file, url } = imageUpload[i];

      if (file) {
        try {
          const bodyFormData = new FormData();
          bodyFormData.append('file', file);

          const response = await fileUpload(bodyFormData);

          if (response && response.data) {
            uploadedImages.push(response.data);
          } else {
            alert.error(`Empty response uploading file: ${file}`, alertOptions);
            uploadedImages.push("");
          }
        } catch (error) {
          alert.error(`Error file upload: ${file} - ${error}`, alertOptions);
          uploadedImages.push("");
        }
      } else if (url) {
        uploadedImages.push(url);
      } else {
        uploadedImages.push("");
      }
    }

    return uploadedImages;
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = ['name', 'price', 'barcode1', 'vendors'];

    requiredFields.forEach(field => {
      if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
        errors[field] = true;
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();

    if (!isValid) {
      toast.warning("Please fill in all the required fields", alertOptions);
      return;
    }

    setIsLoading(true);

    const updatedAccessoryData = { ...formData };

    const productImages = await validateAndUploadImages();
    const formatVendors = formData.vendors.map((vendor) => vendor.value);
    updatedAccessoryData.images = productImages;
    updatedAccessoryData.vendors = formatVendors;

    const getUpdatedFields = (originalData, newData) => {
      const updatedFields = {};

      for (let key in newData) {
        if (newData[key] !== originalData[key]) {
          updatedFields[key] = newData[key];
        }
      }

      return updatedFields;
    };

    if (currentAccessory) {
      const id = currentAccessory._id;

      const updatedFields = getUpdatedFields(currentAccessory, updatedAccessoryData);

      const response = await updateAccesory(id, updatedFields);

      if (response.status === 200) {
        toast.success("Accessory updated successfully", alertOptions);
        refreshAccessoryList(response.data);
        handleClose();
      } else {
        toast.warning(response.data.message, alertOptions);
      }
    } else {
      try {
        const response = await createAccesory(updatedAccessoryData);

        if (response.status === 201) {
          toast.success("Accessory added successfully", alertOptions);
          refreshAccessoryList(response.data);
          handleClose();
        } else {
          toast.error('Error saving product', alertOptions);
        }
      } catch (error) {
        toast.error(`Error sending data: ${error}`, alertOptions);
      }
    }

    setIsLoading(false);
  };

  const fetchData = async () => {
    const accessoryData = currentAccessory;
    const formatVendors = accessoryData.vendors.map((option) => ({
      value: option._id, label: option.name
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      id: accessoryData._id,
      status: accessoryData.status,
      name: accessoryData.name,
      price: accessoryData.price,
      barcode1: accessoryData.barcode1,
      barcode2: accessoryData.barcode2,
      description: accessoryData.description,
      notes: accessoryData.notes,
      vendors: formatVendors,
      images: accessoryData.images,
      updatedAt: accessoryData.updatedAt,
    }));

    const updatedImageUpload = [ ...imageUpload ];

    accessoryData.images.forEach((image, i) => {
      updatedImageUpload[i] = {
        name: `Image ${i + 1}`,
        file: null,
        url: image
      };
    });

    setImageUpload(updatedImageUpload);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteAccessory(currentAccessory._id);

      if (response.status === 200) {
        toast.success("Accessory deleted successfully", alertOptions);
        refreshAccessoryList(null, currentAccessory._id);
        setConfirmDelete(false);
        handleClose();
      } else {
        toast.error(`Error deleting accessory: ${response.data.message}`, alertOptions );
      }
    } catch (error) {
      toast.error(`Error deleting accessory: ${error}`, alertOptions);
    }
  };

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextImage = (e) => {
    e.preventDefault();
    const nextIndex = (currentImageIndex + 1) % imageUpload.length;
    if (imageUpload[nextIndex].file || imageUpload[nextIndex].url) {
      setCurrentImageIndex(nextIndex);
    } else {
      const nextValidIndex = imageUpload.findIndex((img, i) =>
        i > currentImageIndex && (img.file || img.url)
      );
      if (nextValidIndex !== -1) {
        setCurrentImageIndex(nextValidIndex);
      }
    }
  };

  const prevImage = (e) => {
    e.preventDefault();
    const prevIndex = (currentImageIndex - 1 + imageUpload.length) % imageUpload.length;
    if (imageUpload[prevIndex].file || imageUpload[prevIndex].url) {
      setCurrentImageIndex(prevIndex);
    } else {
      const prevValidIndex = imageUpload.findLastIndex((img, i) =>
        i < currentImageIndex && (img.file || img.url)
      );
      if (prevValidIndex !== -1) {
        setCurrentImageIndex(prevValidIndex);
      }
    }
  };

  const hasNextImage = () => {
    return imageUpload.some((img, i) =>
      i > currentImageIndex && (img.file || img.url)
    );
  };

  const hasPrevImage = () => {
    return imageUpload.some((img, i) =>
      i < currentImageIndex && (img.file || img.url)
    );
  };

  useEffect(() => {
    if (currentAccessory) {
      fetchData();
    }
  }, [currentAccessory]);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl h-[90vh] flex flex-col">
            <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white z-10">
              <h2 className="text-2xl font-bold text-gray-800">
                { currentAccessory ? 'Edit Accessory' : 'New Accessory' }
              </h2>

              <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 focus:outline-none ml-4">
                <span className="sr-only">Cerrar</span>
                <span aria-hidden="true" className="text-2xl font-medium">&times;</span>
              </button>
            </div>

            <div className={`flex ${!currentAccessory ? 'flex-end' : ''} justify-between items-center p-4 bg-gray-50 border-b`}>
              <div>
                { currentAccessory ? (
                  <button
                    type="button"
                    onClick={() => setConfirmDelete(true)}
                    className="px-4 py-2 text-sm bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                    aria-label="Delete accessory"
                  >
                    Delete
                  </button>
                )
                  : null
                }
              </div>

              <div className="flex space-x-2">
                <Link to="/inventory">
                  <button type="button" className="px-4 py-2 text-sm bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-150 ease-in-out">
                    See Inventory
                  </button>
                </Link>

                {/* <Link to={"/products/history"}>
                  <button type="button" className="px-4 py-2 text-sm bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-150 ease-in-out">
                    Price History
                  </button>
                </Link> */}

                <button type="submit" onClick={handleSubmit} form="accessoryForm" className="px-4 py-2 text-sm bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-150 ease-in-out">
                  {isLoading ? (
                    <span className="flex items-center">
                      <span style={{ minWidth: "70px" }}>Saving</span>
                      <span className="loading-dots ml-1"></span>
                    </span>
                  ) : (
                    <span style={{ minWidth: "70px" }}>Save</span>
                  )}
                </button>
              </div>
            </div>

            <div className="overflow-y-auto flex-grow p-6">
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
              ) : (
                <form id="accessoryForm" className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>

                    {maxCharReached.name && <p className="text-red-500 text-sm mt-1">You have reached the 60-character limit.</p>}

                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      maxLength="60"
                      className={`w-full px-3 py-2 border ${fieldErrors.name ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500`}
                    />
                  </div>

                  <div>
                    <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-1">Price</label>

                    {maxCharReached.price && <p className="text-red-500 text-sm mt-1">You have reached the 10-character limit.</p>}

                    <input
                      type="text"
                      id="price"
                      name="price"
                      value={formData.price}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                          handleInputChange(e);
                        }
                      }}
                      maxLength="10"
                      className={`w-full px-3 py-2 border ${fieldErrors.price ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500`}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="barcode1" className="block text-sm font-medium text-gray-700 mb-1">Barcode 1</label>
                      <input
                        type="text"
                        id="barcode1"
                        name="barcode1"
                        value={formData.barcode1}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 border ${fieldErrors.barcode1 ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500`}
                      />
                    </div>
                    <div>
                      <label htmlFor="barcode2" className="block text-sm font-medium text-gray-700 mb-1">Barcode 2</label>
                      <input
                        type="text"
                        id="barcode2"
                        name="barcode2"
                        value={formData.barcode2}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description</label>

                    {maxCharReached.description && <p className="text-red-500 text-sm mt-1">You have reached the 500-character limit.</p>}

                    <textarea
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      rows={3}
                      maxLength="500"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    ></textarea>
                  </div>

                  <div>
                    <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">Notes</label>

                    {maxCharReached.notes && <p className="text-red-500 text-sm mt-1">You have reached the 800-character limit.</p>}

                    <textarea
                      id="notes"
                      name="notes"
                      value={formData.notes}
                      onChange={handleInputChange}
                      maxLength="800"
                      rows={3}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    ></textarea>
                  </div>

                  <div>
                    <label htmlFor="vendors" className="block text-sm font-medium text-gray-700 mb-1">Vendors</label>
                    <Select
                      classNames={{
                        control: () => ` ${fieldErrors.vendors ? 'errorSelectDefault' : 'reactSelectDefault'}`
                      }}
                      className="text-center text-xs xl:text-base"
                      onChange={(e) => handleInputChange(e, 'vendors')}
                      options={vendors.map((option) => ({
                        value: option._id, label: option.name
                      }))}
                      placeholder={"Vendors"}
                      value={formData.vendors}
                      isMulti
                    />
                  </div>

                  <div>
                    <span className="block text-sm font-medium text-gray-700 mb-1">Product Images</span>
                    <div className="container mx-auto p-4">
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        {imageUpload.map((image, index) => (
                          <div key={index} className="aspect-square bg-gray-100 flex items-center justify-center rounded-md overflow-hidden">
                            {image.file || image.url ? (
                              <div className="aspect-square bg-gray-100 rounded-md overflow-hidden relative group">
                                <img
                                  src={image.file ? URL.createObjectURL(image.file) : image.url}
                                  alt={`Product image ${index + 1}`}
                                  className="object-cover w-full h-full cursor-pointer"
                                  onClick={() => openModal(index)}
                                />
                                <button
                                  onClick={() => deleteArrImage(index)}
                                  className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  aria-label={`Delete image ${index + 1}`}
                                >
                                  <svg className="w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </button>
                              </div>
                            ) : (
                              <div
                                className="w-full h-full flex items-center justify-center cursor-pointer hover:bg-gray-200"
                                onClick={() => handleClick(index)}
                              >
                                <span className="text-gray-400 text-3xl">+</span>
                                <input
                                  ref={el => (fileInputRefs.current[index] = el)}
                                  type="file"
                                  accept="image/png, image/jpg, image/jpeg"
                                  className="hidden"
                                  onChange={(e) => handleFileChange(e, index)}
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>

                      {modalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                          <div className="bg-white p-4 rounded-lg max-w-3xl w-full">
                            <div className="relative">
                              <img
                                src={imageUpload[currentImageIndex].file ? URL.createObjectURL(imageUpload[currentImageIndex].file) : imageUpload[currentImageIndex].url}
                                alt={`Product image ${currentImageIndex + 1}`}
                                className="w-full h-auto"
                              />
                              <button
                                onClick={closeModal}
                                className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md"
                                aria-label="Close modal"
                              >
                                <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                  <path d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                              </button>
                              {hasPrevImage() && (
                                <button
                                  onClick={(e) => prevImage(e)}
                                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md"
                                  aria-label="Previous image"
                                >
                                  <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M15 19l-7-7 7-7"></path>
                                  </svg>
                                </button>
                              )}
                              {hasNextImage() && (
                                <button
                                  onClick={(e) => nextImage(e)}
                                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md"
                                  aria-label="Next image"
                                >
                                  <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M9 5l7 7-7 7"></path>
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>

          { confirmDelete ?
            <Modal
              title={`¿Are you sure you want to delete this product?`}
              onClose={() => setConfirmDelete(false)}
              onResponse={handleDelete}
            />
            :
            null
          }
        </div>
      )}
    </>
  );
}

export default AccessoryModal;