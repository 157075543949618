import { useContext, useEffect, useState } from "react";
import { searchInventoryAdd, updateInventoryAddStatus } from "../../../services/Inventory";
import { getWarehouses } from "../../../services/Warehouse";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { PermitsContext } from "../../../services/Permits";
import Filters from "./Filters";
import TableInventory from "./TableInventory";
import MovementHistory from "./MovementHistory";
import Loader from "../../../components/loader/Loader";
import Modal from "../../../components/modal/Modal";
import Images from "../../../components/carrousel/Images";
import PricePlinkington from "../../fragments/PricePlinkington";
import ModalOrders from "./ModalOrders";
import ModalCompatible from "../products/ModalCompatible";

const Inventory = () => {
  const { permits, dataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(true);
  const [inventory, setInventory] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [statusOptions] = useState([
    { label: "Active", value: "active" },
    { label: "Dead", value: "dead" }
  ]);
  const [page, setPage] = useState(1);
  const [finalMessage, setFinalMessage] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPagination, setFilterPagination] = useState({ data: {}, status: false });
  const [showModal, setShowModal] = useState({ status: false, element: null });
  const [showHistory, setShowHistory] = useState({ data: "", status: false });
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [showImages, setShowImages] = useState({ status: false, images: [] });
  const [showPrice, setShowPrice] = useState({ status: false, data: "" });
  const [showOrderQuery, setShowOrderQuery] = useState({ status: false, data: "" });
  const [showFilters, setShowFilters] = useState(true);
  const [warehousesAccess, setWarehousesAccess] = useState([]);

  const handleFilters = (filtersParams) => {
    let filterObject = {};
    setPage(1);
    setFinalMessage(false);
    setInventory([]);
    if (
      filtersParams.warehouse === "" && filtersParams.model === "" && filtersParams.brand === "" &&
            filtersParams.year === "" && filtersParams.nag === "" && filtersParams.status === "" && filtersParams.search === ""
    ) {
      setLoader(true);
      setFilterPagination({...filterPagination, data: {}, status: false});
      searchInventoryForPage(1, {});
    } else {
      setLoader(true);
      for (const key in filtersParams) {
        if (Object.hasOwnProperty.call(filtersParams, key)) {
          if (filtersParams[key] !== "" && filtersParams[key] !== "default") filterObject[key] = filtersParams[key];
        }
      }
      setFilterPagination({...filterPagination, data: filterObject, status: true});
      searchInventoryForPage(1, filterObject);
    }
  };

  const searchInventoryForPage = (pageInventory, paramsFilters) => {
    searchInventoryAdd(pageInventory, 4, paramsFilters).then(res => {
      if (res.status === 200 && res.data.productsInventory.length > 0) {
        if (pageInventory === 1) {
          setInventory(res.data.productsInventory);
        } else if(pageInventory > 1){
          let newArrayInventory = inventory.concat(res.data.productsInventory);
          setInventory(newArrayInventory);
        }
        setTotalPages(Math.ceil(res.data.totalRecords/4));
        if (pageInventory >= Math.ceil(res.data.totalRecords/4) || res.data.totalRecords.length <= 4) {
          setFinalMessage(false);
        } else {
          setFinalMessage(true);
        }
      } else {
        toast.warning("Request without records", alertOptions);
        setInventory([]);
      }
      setLoader(false);
    }).catch(() => {
      toast.warning("Request without records", alertOptions);
      setInventory([]);
      setLoader(false);
    });
  };

  const updateStatus = (sts) => {
    if (sts) {
      updateInventoryAddStatus(showModal.element.productInventory._id, showModal.element).then(res => {
        if (res.status === 200) {
          searchInventoryForPage(page, filterPagination.data);
          toast.success("Product updated successfully", alertOptions);
        } else {
          toast.warning(res.response.data.message, alertOptions);
        }
        setShowModal({...showModal, status: false, element: null});
      }).catch(error => {
        toast.warning(error.response.data.message, alertOptions);
      });
    }
  };

  const changePage = () => {
    let nextPage = page + 1;
    if (nextPage <= totalPages) {
      new Promise((resolve) => {
        searchInventoryForPage(nextPage, filterPagination.data);
        resolve();
      }).then(() => {
        setPage(nextPage);
      });
    } else {
      setFinalMessage(false);
    }
  };

  useEffect(() => {
    searchInventoryForPage(1, {});
    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const warehousesCurrent = res.data.filter(element => !element.deleted);
        setWarehouses(warehousesCurrent);
      }
    }, []);
  }, []);

  useEffect(() => {
    const userWarehouses = dataSession.allWarehouse.map((warehouse) => ({ _id: warehouse._id }));
    setWarehousesAccess(userWarehouses);
  }, [dataSession]);


  return (
    <>
      { loader && <Loader /> }

      <>
        <div className="pt-3 px-3 md:px-8">
              <Filters
                enableFilters={permits['INVENTORY']['FILTER']}
                enableActions={permits['INVENTORY']['ACTIONS']}
                enableWarehouse={dataSession.warehouse}
                warehousesOptions={warehouses}
                statusOptions={statusOptions}
                onFilters={handleFilters}
                setShowFilters={setShowFilters}
                showFilters={showFilters}
              />
            </div>

            <div className="pt-3 px-3 md:px-8">
              <TableInventory
                enableEdit={permits['INVENTORY']['EDIT']}
                enableRecord={permits['INVENTORY']['RECORD']}
                enableOrderQuery={permits['INVENTORY']['ORDER_QUERY']}
                arrInventory={inventory}
                onPage={(pg) => changePage(pg)}
                onHistory={(id) => setShowHistory({...showHistory, data: id, status: true})}
                onStatus={(inventoryElement) => setShowModal({...showModal, status: true, element: inventoryElement })}
                onImage={(imagesElement) => setShowImages({...showImages, status: true, images: imagesElement})}
                onPrice={(nagRes) => setShowPrice({...showPrice, status: true, data: nagRes})}
                onOrder={(idInv) => setShowOrderQuery({...showOrderQuery, status: true, data: idInv})}
                onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                finalMessage={finalMessage}
                showFilters={showFilters}
                warehousesAccess={warehousesAccess}
              />
            </div>
      </>
      :
      <></>

      { showModal.status ?
        <Modal
          title={`¿Are you sure you want to change the status to ${ showModal.element.productInventory.status}?`}
          onClose={() => {setShowModal({...showModal, status: false, element: null}); }}
          onResponse={updateStatus}
        />
        :
        null
      }

      {
        showHistory.status ?
          <div>
            <MovementHistory
              id={showHistory.data}
              onClose={() => setShowHistory({...showHistory, data: "", status: false})}
            />
          </div>
          : null
      }

      {
        showImages.status ?
          <div>
            <Images
              images={showImages.images}
              onClose={() => setShowImages({...showImages, status: false, images: []})}
            />
          </div>
          : null
      }

      {
        showPrice.status ?
          <PricePlinkington
            nagSearch={showPrice.data}
            onLoader={(val) => setLoader(val)}
            onClose={() => setShowPrice(false)}
          />
          : null
      }

      {
        showOrderQuery.status ?
          <ModalOrders
            idInventory={showOrderQuery.data}
            onClose={() => setShowOrderQuery({...showOrderQuery, status: false, data: ""})}
          />
          : null
      }

      {
        showCompatible.status ?
          <ModalCompatible
            products={showCompatible.element}
            onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
          />
          : null
      }
    </>
  );
};

export default Inventory;