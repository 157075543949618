const Loader = () => {
  return (
    <>
      <div className="loading">
        <div className="spiners flex items-center justify-center">
          <div className="flex items-center gap-[15px] p-5 border-0 rounded-lg shadow-lg bg-[#00000099] outline-none focus:outline-none">
            <span className="loader"></span>
            <span className="text-white text-base md:text-xl">
              Loading, please wait...
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
