import RowsProducts from "./RowsProducts";
import WithoutRows from "../../../components/table/WithoutRows";

const TableProducts = ({ enableEditR, products, available, productSelected, onSelect, onEdit, onDelete, onCompatible }) => {
  return (
    <div className={products.length > 0 ? "" : "pb-5"}>
      <table className="w-full text-center text-ag-secondary">
        <thead>
          { available ?
            <tr className="bg-ag-secondary-table">
              <th className="w-[75%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-24 md:px-2 py-4">Available Products</th>
              <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-5 md:px-2 py-4">Remaining maximum stock</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-5 md:px-2 py-4">Select</th>
            </tr>
            :
            <tr className="bg-ag-secondary-table">
              <th className="w-[65%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-24 md:px-2 py-4">Products</th>
              <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-5 md:px-2 py-4">Remaining maximum stock</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-5 md:px-2 py-4">Edit</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-5 md:px-2 py-4">Delete</th>
            </tr>
          }
        </thead>
        <tbody>
          {
            products.length > 0 ? products.map((element, i) => {
              return (
                <RowsProducts
                  key={i}
                  enableEditR={enableEditR}
                  product={element}
                  available={available}
                  productSelected={productSelected}
                  onSelect={onSelect}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onCompatible={onCompatible}
                />
              );
            }) : <WithoutRows colspan={available ? 3 : 4} />
          }
        </tbody>
      </table>
    </div>
  );
};

export default TableProducts;