import { useEffect, useState } from "react";
import { alertOptions, } from "../../../../utils/Utilities";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import ModalNumber from "../../../../components/modal/ModalNumber";

const Filters = ({ enableFilters, warehouseOptions, yearOptions, monthOptions, onModal, productSelected, onFilterGeneral, onFilterProducts, onFilterWithLimits }) => {
  const [productButton, setProductButton] = useState({ all: false, unique: false });
  const [disabledInput, setDisabledInput] = useState(false);
  const [showModal, setShowModal] = useState({ status: false, data: "" });
  const [searchType, setSearchType] = useState({ search_date_range: false, search_product: false });
  const [dateRange, setDateRange] = useState(false);

  const [sellerData, setSellerData] = useState({
    best: { status: false, value: 0 },
    regular: { status: false, value: 0 },
    minor: { status: false, value: 0 }
  });

  const [focusClass, setFocusClass] = useState({
    year: { placeholder: "Year", status: false },
    month: { placeholder: "Month", status: false },
    warehouse: { placeholder: "Warehouse", status: false },
    startDate: { placeholder: "Start date", status: false },
    endDate: { placeholder: "End date", status: false }
  });

  const [options, setOptions] = useState({
    year: "",
    month: "",
    warehouse: "",
    startDate: "",
    endDate: "",
    product: ""
  });

  const cleanFileds = () => {
    setOptions({...options,
      year: `${ new Date().getFullYear() }`,
      month: "",
      warehouse: "",
      startDate: "",
      endDate: "",
      product: ""
    });

    setFocusClass({...focusClass,
      year: { placeholder: "Year", status: true },
      month: { placeholder: "Month", status: false },
      warehouse: { placeholder: "Warehouse", status: false },
      startDate: { placeholder: "Start date", status: false },
      endDate: { placeholder: "End date", status: false }
    });

    setSellerData({...sellerData,
      best: { status: false, value: 0 },
      regular: { status: false, value: 0 },
      minor: { status: false, value: 0 }
    });

    setDisabledInput(false);
    setSearchType({...searchType, search_date_range: false,  search_product: false});
    setProductButton({...productButton, all: false, unique: false});
    document.getElementById("search_date_range").checked = false;

    onFilterGeneral({ year: new Date().getFullYear() });
    onFilterProducts({ year: new Date().getFullYear() });
  };

  const handleProduct = (type) => {
    if (type === "all") {
      setProductButton({...productButton, all: !productButton.all, unique: false});
      setOptions({...options, product: ""});
    } else {
      onModal(true);
    }
  };

  const handleInputProducts = (nbrVal, typeTxt) => {
    setShowModal({...showModal, status: false, data: ""});
    if (typeTxt === "best") {
      setSellerData({...sellerData,
        best: {status: true, value: nbrVal },
        regular: { status: false, value: 0 },
        minor: { status: false, value: 0 }
      });
    } else if (typeTxt === "regular") {
      setSellerData({...sellerData,
        best: {status: false, value: 0 },
        regular: { status: true, value: nbrVal },
        minor: { status: false, value: 0 }
      });
    } else {
      setSellerData({...sellerData,
        best: {status: false, value: 0 },
        regular: { status: false, value: 0 },
        minor: { status: true, value: nbrVal }
      });
    }
  };

  const handleChangeTypeFilter = (evt) => {
    if (evt.target.name === "search_date_range") {
      if (evt.target.checked) {
        setDisabledInput(true);
      } else {
        setDisabledInput(false);
      }
    }
    setSearchType({...searchType, [evt.target.name]: evt.target.checked});
  };

  const handleChange = (evt) =>{
    if (evt.currentTarget.value === "default") {
      setOptions({...options, [evt.currentTarget.name]: ""});
    } else {
      setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
    }
  };

  const handleChangeSeller = (typeSeller) => {
    setShowModal({...showModal, status: true, data: typeSeller});
    if (typeSeller === "best") {
      setSellerData({...sellerData,
        best: {status: !sellerData.best.status, value: 0 },
        regular: { status: false, value: 0 },
        minor: { status: false, value: 0 }
      });
    } else if (typeSeller === "regular") {
      setSellerData({...sellerData,
        best: {status: false, value: 0 },
        regular: { status: !sellerData.regular.status, value: 0 },
        minor: { status: false, value: 0 }
      });
    } else {
      setSellerData({...sellerData,
        best: {status: false, value: 0 },
        regular: { status: false, value: 0 },
        minor: { status: !sellerData.minor.status, value: 0 }
      });
    }
  };

  const handleSubmit = (e) =>{
    e.preventDefault();

    let filtObj = {};
    for (const key in options) {
      if (Object.hasOwnProperty.call(options, key) && options[key] !== "") filtObj[key] = options[key];
    }

    if (searchType.search_date_range) {
      delete filtObj.year;
      delete filtObj.month;
    } else {
      delete filtObj.startDate;
      delete filtObj.endDate;
    }

    // Busqueda [General Date]
    onFilterGeneral(filtObj);

    // Busqueda [Producto]
    if (disabledInput && (!filtObj.endDate || !filtObj.startDate)) {
      toast.info("Please select a valid date range", alertOptions);
    } else {
      const newFiltObj = filtObj;
      if (options.product !== "") newFiltObj.nag = options.product;
      if (sellerData.best.status === true || sellerData.regular.status === true || sellerData.minor.status === true) {
        if (sellerData.best.status === true) {
          newFiltObj.bestSeller = sellerData.best.value;
        } else if (sellerData.regular.status === true) {
          newFiltObj.regularSale = sellerData.regular.value;
        } else {
          newFiltObj.minorSold = sellerData.minor.value;
        }
        onFilterWithLimits(newFiltObj);
      } else {
        onFilterProducts(newFiltObj);
      }
    }
  };

  useEffect(() => {
    setOptions({...options, year: `${new Date().getFullYear()}`});
    setFocusClass({...focusClass, year: { placeholder: "Year", status: true }});
  }, []);

  useEffect(() => {
    if (productSelected !== "") {
      setOptions({...options, product: productSelected});
      setProductButton({...productButton, all: false, unique: true});
    }
  }, [productSelected]);

  return (
    <>
      {
        showModal.status ?
          <ModalNumber
            title="Query product limit"
            onClose={() => {
              setShowModal({...showModal, status: false, data: ""});
              setSellerData({...sellerData,
                best: { status: false, value: 0 },
                regular: { status: false, value: 0 },
                minor: { status: false, value: 0 }
              });
            }}
            onResponse={(nbrVal) => handleInputProducts(nbrVal, showModal.data)}
          />
          : null
      }

      <div className="border border-ag-secondary-light px-8 py-4 lg:px-14 lg:py-9 rounded-xl">
        <form onSubmit={handleSubmit}>
          <div className="md:flex md:justify-between md:items-center md:space-x-3">
            <div className="w-full md:w-[80%]">
              <div className="grid gap-3 xl:gap-8 grid-cols-3 pb-5 lg:pb-6 border-b border-ag-secondary-light">
                <div>
                  <select
                    className={`w-full p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl ${focusClass.year.status ? "text-left" : "text-center"}`}
                    name="year"
                    defaultValue={`${new Date().getFullYear()}`}
                    value={options.year}
                    onChange={handleChange}
                    onFocus={() => setFocusClass({...focusClass, year: { placeholder: "", status: true }})}
                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, year: { placeholder: "Year", status: false }}) : null}
                    disabled={disabledInput || !enableFilters}
                  >
                    <option value="default">Year</option>
                    {
                      yearOptions.map((element, index) => {
                        return <option key={index} value={element.value}>{element.label}</option>;
                      })
                    }
                  </select>
                </div>

                <div>
                  <select
                    className={`w-full p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl truncate ${focusClass.month.status ? "text-left" : "text-center"}`}
                    name="month"
                    defaultValue="default"
                    value={options.month}
                    onChange={handleChange}
                    onFocus={() => setFocusClass({...focusClass, month: { placeholder: "", status: true }})}
                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, month: { placeholder: "Month", status: false }}) : null}
                    disabled={disabledInput || !enableFilters}
                  >
                    <option value="default">Month</option>
                    {
                      monthOptions.map((element, index) => {
                        return <option key={index} value={element.value}>{element.label}</option>;
                      })
                    }
                  </select>
                </div>

                <div>
                  <select
                    className={`w-full p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl truncate ${focusClass.warehouse.status ? "text-left" : "text-center"}`}
                    name="warehouse"
                    defaultValue="default"
                    value={options.warehouse}
                    disabled={!enableFilters}
                    onChange={handleChange}
                    onFocus={() => setFocusClass({...focusClass, warehouse: { placeholder: "", status: true }})}
                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, warehouse: { placeholder: "Warehouse", status: false }}) : null}
                  >
                    <option value="default">Warehouse</option>
                    {
                      warehouseOptions.map((element, index) => {
                        return <option key={index} value={element._id}>{element.name}</option>;
                      })
                    }
                  </select>
                </div>
              </div>

              <div className="grid gap-3 xl:gap-8 grid-cols-3 py-5 lg:py-6">
                <div className="flex justify-center items-center space-x-3">
                  <label className="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      id="search_date_range"
                      name="search_date_range"
                      className="sr-only peer"
                      disabled={!enableFilters}
                      onChange={handleChangeTypeFilter}
                    />
                    <div className="w-10 h-5 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-ag-active z-[-1]"></div>
                  </label>

                  <span className="text-ag-secondary-letter text-xs xl:text-sm">Search by date range</span>
                </div>

                <div className="customDatePickerWidth">
                  <ReactDatePicker
                    className={`w-full p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl disabled:bg-zinc-200 ${focusClass.startDate.status ? "text-left" : "text-center"}`}
                    placeholderText="Start date"
                    name="startDate"
                    selected={options.startDate}
                    onChange={(dateVal) => setOptions({...options, startDate: dateVal})}
                    onFocus={() => setFocusClass({...focusClass, startDate: { placeholder: "", status: true }})}
                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, startDate: { placeholder: "Start date", status: false }}) : <></> }
                    disabled={!enableFilters}
                  />
                </div>

                <div className="customDatePickerWidth">
                  <ReactDatePicker
                    className={`w-full p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl disabled:bg-zinc-200 ${focusClass.endDate.status ? "text-left" : "text-center"}`}
                    placeholderText="End date"
                    name="endDate"
                    selected={options.endDate}
                    onChange={(dateVal) => setOptions({...options, endDate: dateVal})}
                    onFocus={() => setFocusClass({...focusClass, endDate: { placeholder: "", status: true }})}
                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, endDate: { placeholder: "End date", status: false }}) : <></> }
                    disabled={!enableFilters}
                  />
                </div>
              </div>
            </div>

            <div className="w-full md:w-[20%] space-y-1 pb-3 md:pb-0">
              <button className="w-full bg-ag-secondary text-white text-xs xl:text-sm px-3 py-1 rounded-xl disabled:bg-zinc-200" disabled={!enableFilters} type="submit">
                Filter
              </button>

              <button className="w-full bg-ag-secondary text-white text-xs xl:text-sm px-3 py-1 rounded-xl disabled:bg-zinc-200" disabled={!enableFilters} type="button" onClick={() => cleanFileds()}>
                Clean
              </button>
            </div>
          </div>

          <div className="md:flex md:justify-between md:items-center md:space-x-3 pt-5 border-t border-ag-secondary-light">
            <div className="w-full md:w-[80%]">
              <div className="grid gap-3 xl:gap-8 grid-cols-3">
                <div className="flex justify-center items-center space-x-3">
                  <span className="text-ag-secondary-letter text-xs xl:text-sm">Search by products</span>
                </div>

                <div className="col-span-2 space-y-3">
                  <button
                    className={`w-full text-ag-secondary-letter text-xs xl:text-sm border border-ag-secondary-light px-3 py-1 rounded-xl disabled:bg-zinc-200 ${ productButton.unique ? "bg-zinc-200" : "bg-white" }`}
                    type="button"
                    disabled={!enableFilters}
                    onClick={() => handleProduct("")}
                  >
                    { options.product !== "" ? options.product : "Product" }
                  </button>

                  <button
                    className={`w-full text-ag-secondary-letter text-xs xl:text-sm border border-ag-secondary-light px-3 py-1 rounded-xl disabled:bg-zinc-200 ${ productButton.all ? "bg-zinc-200" : "bg-white"}`}
                    type="button"
                    disabled={!enableFilters}
                    onClick={() => handleProduct("all")}
                  >
                    All the products
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full md:w-[20%] space-y-3 pt-3 md:pt-0">
              <button
                className={`w-full ${ sellerData.best.status ? "bg-green-800 shadow-xl" : "bg-green-600" } text-white text-xs xl:text-sm px-3 py-1 rounded-xl disabled:bg-zinc-200`}
                disabled={!enableFilters}
                type="button"
                onClick={() => handleChangeSeller("best")}
              >
                Best seller { sellerData.best.value > 0 ? ` (${sellerData.best.value})` : "" }
              </button>

              <button
                className={`w-full ${ sellerData.regular.status ? "bg-yellow-300 text-black shadow-xl" : "bg-yellow-200 text-ag-secondary-letter" } text-xs xl:text-sm px-3 py-1 rounded-xl disabled:bg-zinc-200`}
                disabled={!enableFilters}
                type="button"
                onClick={() => handleChangeSeller("regular")}
              >
                Regular sale { sellerData.regular.value > 0 ? ` (${sellerData.regular.value})` : "" }
              </button>

              <button
                className={`w-full ${ sellerData.minor.status ? "bg-red-500 text-white shadow-xl" : "bg-red-400 text-white" } text-xs xl:text-sm px-3 py-1 rounded-xl disabled:bg-zinc-200`}
                disabled={!enableFilters}
                type="button"
                onClick={() => handleChangeSeller("minor")}
              >
                Minor sold { sellerData.minor.value > 0 ? ` (${sellerData.minor.value})` : "" }
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Filters;