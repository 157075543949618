import { useEffect, useState } from "react";
import { getAllAccessories, updateAccesory } from "../../../services/Accessories";
import AccessoryModal from "./save_product/AccessoryModal";
import { getVendors } from "../../../services/Vendor";
import Modal from "../../../components/modal/Modal";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";

export default function Accessories() {
  const [accessories, setAccessories] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [accesorySelected, setAccesorySelected] = useState('');
  const [statusFilter, setStatusFilter] = useState("all");
  const [barcodeFilter, setBarcodeFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [vendors, setVendors] = useState([]);
  const [confirmStatus, setConfirmStatus] = useState({ open: false, element: null, index: null});

  const toggleStatus = async () => {
    setConfirmStatus({ open: false, element: null, index: null});

    try {
      const actualAccesory = confirmStatus.element;
      const newAccesory = { ...actualAccesory, status: !actualAccesory.status };

      const updatedAccesory = await updateAccesory(actualAccesory._id, newAccesory);

      if (updatedAccesory.status === 200) {
        toast.success("Accessory updated successfully", alertOptions);
        const updatedAccessories = accessories.map((acc, i) =>
          i === confirmStatus.index ? updatedAccesory.data : acc
        );
        setAccessories(updatedAccessories);
      }
    } catch (error) {
      toast.error(`Updating Error: ${error}` , alertOptions);
    }
  };

  const refreshAccessoryList = (newAccessory, deletedAccessoryId = null) => {
    if (newAccessory) {
      const updatedAccessories = accessories.map((acc) =>
        acc._id === newAccessory._id ? newAccessory : acc
      );

      if (!updatedAccessories.find((acc) => acc._id === newAccessory._id)) {
        updatedAccessories.unshift(newAccessory);
      }

      setAccessories(updatedAccessories);
    } else if (deletedAccessoryId) {
      const updatedAccessories = accessories.filter((acc) => acc._id !== deletedAccessoryId);
      setAccessories(updatedAccessories);
    }
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleBarcodeChange = (event) => {
    setBarcodeFilter(event.target.value);
  };

  const handleNameChange = (event) => {
    setNameFilter(event.target.value.toLowerCase());
  };

  const filteredAccessories = accessories.filter((acc) => {
    const matchesStatus = statusFilter === "all" ? true : (statusFilter === "active" ? acc.status : !acc.status);
    // const matchesBarcode = acc.barcode1.includes(barcodeFilter) || acc.barcode2.includes(barcodeFilter);
    const matchesName = acc.name.toLowerCase().includes(nameFilter);

    return matchesStatus && matchesName;
  });

  useEffect(() => {
    const getData = async () => {
      const responseAccesories = await getAllAccessories();

      if (responseAccesories.status === 200) {
        setAccessories(responseAccesories.data);
      }

      const responseVendors = await getVendors();

      if (responseVendors.status === 200) {

        setVendors(responseVendors.data);
      }
    };

    getData();
  }, []);

  return (
    <div>
      <div className="flex flex-wrap gap-4 mb-6">
        <select
          className="bg-white border rounded-md px-3 py-2 w-full sm:w-auto"
          onChange={handleStatusChange}
          value={statusFilter}
        >
          <option value="all">All</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>

        <input
          type="text"
          placeholder="Barcode"
          className="border rounded-md px-3 py-2 w-full sm:w-auto"
          onChange={handleBarcodeChange}
          value={barcodeFilter}
        />

        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search"
            className="border rounded-md pl-10 pr-3 py-2 w-full"
            onChange={handleNameChange}
            value={nameFilter}
          />
          <svg className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 mb-6">
        <button
          className="bg-red-500 text-white px-4 py-2 rounded-md flex items-center"
          onClick={() => { setOpenModal(true); }}
        >
          <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>

          New product
        </button>


        {/* <Link to={"/products/history"}>
          <button className="bg-red-500 text-white px-4 py-2 rounded-md flex items-center">
            <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

            Price History
          </button>
        </Link> */}
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notes</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vendors</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Barcode 1</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Barcode 2</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAccessories.map((product, index) => (
                <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100`}>
                  <td className="px-6 py-4 text-sm text-gray-900">{product.name}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{product.description}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{product.notes}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex flex-col">
                      {product.vendors.map((option) => (
                        <span key={option._id}>
                          {option.name}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.price}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.barcode1}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.barcode2}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() => {
                        setAccesorySelected(product);
                        setOpenModal(true);
                      }}
                    >
                      <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                      </svg>
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => {
                        setConfirmStatus({ open: true, element: product, index });
                      }}
                      className={`relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                        product.status ? 'bg-green-500' : 'bg-gray-200'
                      }`}
                    >
                      <span className="sr-only">Toggle status</span>
                      <span
                        aria-hidden="true"
                        className={`inline-block h-5 w-5 rounded-full bg-white shadow transform  ring-0 transition ease-in-out duration-200 ${
                          product.status ? 'translate-x-5' : 'translate-x-0'
                        }`}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AccessoryModal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
          setAccesorySelected('');
        }}
        currentAccessory={accesorySelected}
        refreshAccessoryList={refreshAccessoryList}
        vendors={vendors}
      />

      { confirmStatus.open ?
        <Modal
          title={`¿Are you sure you want to ${ !confirmStatus.element.status ? "activate" : "deactivate"} the product?`}
          onClose={() => {
            setConfirmStatus({ open: false, element: null, index: null });
          }}
          onResponse={toggleStatus}
        />
        :
        null
      }
    </div>
  );
}
